import CachedIcon from "@mui/icons-material/Cached";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Chip, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import { AgGridReact } from "ag-grid-react";
import { Spinner } from "impact-ui";
import { cloneDeep } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAlertsAndExceptionsAndUpdateState,
    getTopFiveMaterials,
    setInnerTableToggleObject,
    setMaterials as setReduxMaterials,
    updateSimilarPFSMaterials,
} from "../../../store/features/workbench/workbench";
import { fallbackFormatter } from "../../../utils/helpers/formatter";
import PriceListLevelImageCell from "../../../utils/reactTable/cellRenderers/PriceListLevelImageCell";
import { ImageTooltipRenderer } from "../../../utils/reactTable/tooltipRenderer/TooltipRenderer";
import {
    attributeColumnDefMap,
    euroFormatter,
    rowExpandeEvent,
} from "./EUPriceListLevelPrices";

const page = "filterByAttributes";
export const InnerTableToggleCell = (props) => {
    // console.log(props)
    const {
        data,
        node,
        value,
        type,
        rowIndex,
        toggleCellType,
        showAuditLog,
        selectedMaterialForGlobalSearch,
        setSelectedMaterialForGlobalSearch,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const {
        general: { innerTableToggleObject },
    } = useSelector((state) => state.workbench);

    const isSameRowWithDifferentTypeOpen =
        innerTableToggleObject?.rowIndex == rowIndex &&
        innerTableToggleObject?.toggleCellType !== toggleCellType &&
        innerTableToggleObject.isOpen;

    const dispatch = useDispatch();

    const isSimilar = toggleCellType == "similarMaterial";

    const updateType = isSimilar
        ? data.similar_material_update_type
        : data.pfs_was_material_update_type;

    useEffect(() => {
        if (
            innerTableToggleObject?.rowIndex !== rowIndex ||
            innerTableToggleObject?.toggleCellType !== toggleCellType
        ) {
            setIsOpen(false);
        }

        if (
            innerTableToggleObject?.rowIndex == rowIndex &&
            innerTableToggleObject?.toggleCellType == toggleCellType &&
            innerTableToggleObject?.isOpen
        ) {
            setIsOpen(innerTableToggleObject?.isOpen);
        }
    }, [innerTableToggleObject]);

    const onToggleCollapse = async () => {
        if (isSameRowWithDifferentTypeOpen) return;
        const isOpenTemp = !isOpen;

        if (isOpenTemp && innerTableToggleObject?.rowIndex != rowIndex) {
            rowExpandeEvent({
                rowIndex: innerTableToggleObject?.rowIndex,
                isOpen: false,
            });
        }

        dispatch(
            setInnerTableToggleObject({
                rowIndex,
                toggleCellType,
                isOpen: isOpenTemp,
                parentMaterial: data,
            })
        );

        setIsOpen(isOpenTemp);
        rowExpandeEvent({ rowIndex, isOpen: isOpenTemp });
    };

    const chipStyles = {};
    if (updateType?.charAt(0) == "G") {
        chipStyles.background = "#7d9a32";
    } else if (updateType?.charAt(0) == "M") {
        chipStyles.background = "#8c127a";
    } else {
        chipStyles.background = "#dbb702";
    }

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
                className="inner-table-toggle-button"
                style={{
                    color: isSameRowWithDifferentTypeOpen
                        ? "#1d1d1d00"
                        : "#1d1d1d",
                }}
                onClick={onToggleCollapse}
            >
                {isOpen ? (
                    <KeyboardArrowDownIcon style={{ width: 20 }} />
                ) : (
                    <KeyboardArrowRightIcon style={{ width: 20 }} />
                )}
            </div>

            {updateType && (
                <Tooltip title={updateType}>
                    <div style={{ cursor: "pointer" }}>
                        <Chip
                            label={updateType.charAt(0)}
                            color="primary"
                            size="small"
                            style={chipStyles}
                        />
                    </div>
                </Tooltip>
            )}

            <div>
                <Button
                    variant="text"
                    onClick={() => {
                        showAuditLog({
                            data: node.data,
                            value,
                            type: "MSRP",
                            logType: toggleCellType,
                            screen: "base-pricing",
                        });
                    }}
                >
                    {value || "-"}
                </Button>
            </div>

            <Tooltip title="Global Replace">
                <div className="pfs-inner-table-replace-button global-search-button">
                    <CachedIcon
                        onClick={() =>
                            setSelectedMaterialForGlobalSearch({
                                ...data,
                                isSimilar,
                            })
                        }
                    />
                </div>
            </Tooltip>
        </div>
    );
};

InnerTableToggleCell.propTypes = {
    rowIndex: PropTypes.number,
    toggleCellType: PropTypes.string,
};

const CellRendererForInnerTable = (props) => {
    const {
        rowIndex,
        data,
        value,
        showLoader,
        hideLoader,
        reload,
        materials,
        setMaterials,
    } = props;
    const {
        general: { innerTableToggleObject },
        [page]: { materials: storeMaterials },
    } = useSelector((state) => state.workbench);
    const materialsCopy = cloneDeep(storeMaterials);
    const dispatch = useDispatch();

    const isSimilar =
        innerTableToggleObject.toggleCellType == "similarMaterial";

    const onUpdateClick = () => {
        if (rowIndex == 0 || data.current_selection) return;
        showLoader();
        let parentMaterial = cloneDeep(innerTableToggleObject.parentMaterial);

        // rowExpandeEvent({
        //     rowIndex: innerTableToggleObject.rowIndex,
        //     isOpen: false,
        // });

        dispatch(
            updateSimilarPFSMaterials({
                source_pfm_id: parentMaterial.id,
                material_pfm_id: data.id,
                search_type: isSimilar ? "similar" : "pfs",
                is_global: false,
            })
        )
            .then((res) => {
                const responseMaterial = res[0];

                if (isSimilar) {
                    parentMaterial.similar_material_channel =
                        responseMaterial.channel;
                    parentMaterial.similar_material_market_id =
                        responseMaterial.market_id;
                    parentMaterial.similar_material_market_name =
                        responseMaterial.market_name;
                    parentMaterial.similar_material = responseMaterial.material;
                    parentMaterial.similar_material_season =
                        responseMaterial.season;
                    parentMaterial.similar_material_update_type =
                        responseMaterial.update_type;
                    parentMaterial.similar_material_image_url =
                        responseMaterial.image_url;
                } else {
                    parentMaterial.pfs_was_material_channel =
                        responseMaterial.channel;
                    parentMaterial.pfs_was_material_market_id =
                        responseMaterial.market_id;
                    parentMaterial.pfs_was_market_name =
                        responseMaterial.market_name;
                    parentMaterial.pfs_was_material = responseMaterial.material;
                    parentMaterial.pfs_was_indicative =
                        responseMaterial.pfs_was_indicative;
                    parentMaterial.pfs_was_material_season =
                        responseMaterial.season;
                    parentMaterial.store_price = responseMaterial.store_price;
                    parentMaterial.pfs_was_material_update_type =
                        responseMaterial.update_type;
                    parentMaterial.pfs_was_image_url =
                        responseMaterial.image_url;
                }

                parentMaterial.msrp_price = responseMaterial.msrp_price;

                dispatch(
                    setReduxMaterials({
                        page,
                        data: [...materialsCopy],
                    })
                );
                const foundIndex = materials.findIndex(
                    (item) => item.id == parentMaterial.id
                );
                materials[foundIndex] = parentMaterial;
                setMaterials([...materials]);
                reload({ rowIndex });
            })
            .then(() => {
                return dispatch(
                    getAlertsAndExceptionsAndUpdateState({
                        screen: "price_grid",
                        material_ids: [
                            parentMaterial.material_number +
                                parentMaterial.season_code,
                        ],
                        market_id: parentMaterial.market_id,
                        region: parentMaterial.region,
                        channel: parentMaterial.channel,
                    })
                );
            })
            .catch((err) => console.error(err))
            .finally(() => {
                hideLoader();
                rowExpandeEvent({
                    rowIndex: innerTableToggleObject.rowIndex,
                    isOpen: true,
                });
            });
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "100%",
            }}
        >
            <Tooltip
                title={
                    rowIndex == 0 || data.current_selection
                        ? ""
                        : "Replace With Current"
                }
            >
                <div
                    className={`pfs-inner-table-replace-button ${
                        rowIndex == 0 || data.current_selection
                            ? "hide-replace-button"
                            : "view-replace-button"
                    }`}
                >
                    <CachedIcon onClick={onUpdateClick} />
                </div>
            </Tooltip>
            <div>{value}</div>
        </div>
    );
};

const InnerTableFormatComponent = (props) => {
    const {
        colDef: { field },
        value,
        data,
        valueFormatted,
        rowIndex,
    } = props;

    const isValueDifferent = useMemo(() => {
        if (!rowIndex) return false;

        const firstRowData = props.api.getDisplayedRowAtIndex(0).data;

        return (
            firstRowData[field] !== value &&
            !field.toLowerCase().includes("market_name")
        );
    }, []);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div>{valueFormatted || value || "-"}</div>
            {isValueDifferent && (
                <div
                    style={{
                        width: 10,
                        height: 10,
                        marginLeft: 10,
                        backgroundColor: "red",
                        borderRadius: "50%",
                    }}
                ></div>
            )}
        </div>
    );
};

const InnerTableComponent = (props) => {
    const {
        appliedTableSettings,
        data: { material_number },
        node,
        materials,
        setMaterials,
        gridRef: parentGridRef,
    } = props;
    const [rowData, setRowData] = useState([]);

    const {
        general: {
            selectedMarkets,
            selectedRegions,
            selectedChannels,
            innerTableToggleObject,
        },
    } = useSelector((state) => state.workbench);
    const dispatch = useDispatch();
    const gridRef = useRef();
    const isSimilar =
        innerTableToggleObject?.toggleCellType == "similarMaterial";

    const getMaterials = () => {
        showLoader();
        const payload = {
            region: selectedRegions[0].label,
            channel: selectedChannels[0].label,
            material_number: material_number,
            market_id: selectedMarkets[0].value,
            search_type: isSimilar ? "similar" : "pfs",
            pfm_id: node?.parent?.data?.id || "",
        };

        dispatch(getTopFiveMaterials(payload))
            .then((res) => {
                setRowData(
                    res.map((item) => ({
                        ...item,
                        store_price:
                            item.channel == "OUTLET"
                                ? item.store_price
                                : item.wholesale_price,
                    }))
                );
            })
            .catch(() => {
                // params.successCallback([]);
            })
            .finally(() => hideLoader());
    };

    const showLoader = () => gridRef.current?.api?.showLoadingOverlay();
    const hideLoader = () => gridRef.current?.api?.hideOverlay();
    const reload = () => getMaterials();

    const columnDefs = useMemo(() => {
        return [
            {
                headerName:
                    attributeColumnDefMap[
                        isSimilar ? "similarMaterial" : "pfsWasMaterial"
                    ].columnDef.headerName,
                field: "material_number",
                pinned: true,
                cellRenderer: CellRendererForInnerTable,
                cellRendererParams: {
                    showLoader,
                    hideLoader,
                    reload,
                    materials,
                    setMaterials,
                },
            },
            {
                field: "image_url",
                headerName:
                    attributeColumnDefMap[isSimilar ? "smImage" : "pfsWasImage"]
                        .columnDef.headerName,
                pinned: true,
                lockPinned: true,
                sortable: false,
                suppressMovable: true,
                filter: false,
                cellStyle: {
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                },
                cellRenderer: (data) => <PriceListLevelImageCell data={data} />,
                width: "80px",
                tooltipComponent: ImageTooltipRenderer,
                tooltipField: "image_url",
            },
            {
                headerName:
                    attributeColumnDefMap[
                        isSimilar ? "smSeason" : "pfsWasSeason"
                    ].columnDef.headerName,
                field: "season_name",
            },
            {
                headerName:
                    attributeColumnDefMap[
                        isSimilar ? "smMarket" : "pfsWasMarket"
                    ].columnDef.headerName,
                field: "market_name",
            },
            {
                headerName:
                    attributeColumnDefMap.smChannel.columnDef.headerName,
                field: "channel",
                columnType: "similarMaterial",
            },
            {
                headerName: "PFS WAS Mapping Type",
                field: "mapping_type",
            },
            {
                headerName: "MSRP/PFS WAS",
                nameForFilter: "PFS WAS (EUR)",
                field: "msrp_price",
                valueFormatter: euroFormatter,
            },
            {
                headerName: "Discount",
                field: "discount",
                valueFormatter: ({ value }) => {
                    if ((value || value === 0) && value !== "-") {
                        return `${value}%`;
                    }

                    return "-";
                },
            },
            {
                headerName: "WHSL/STORE",
                nameForFilter: "Store Price",
                field: "store_price",
                valueFormatter: euroFormatter,
            },
            {
                headerName: isSimilar
                    ? attributeColumnDefMap.rtlPriceEffectiveDate.columnDef
                          .headerName
                    : "PFS WAS Eff Date",
                field: "msrp_effective_date",

                valueFormatter: ({ value }) =>
                    value && value !== "-"
                        ? moment(value).format("MM/DD/YYYY")
                        : value,
            },
            {
                headerName: isSimilar
                    ? attributeColumnDefMap.whslPriceEffectiveDate.columnDef
                          .headerName
                    : "Store Eff Date",
                field: "wholesale_effective_date",
                valueFormatter: ({ value }) =>
                    value && value !== "-"
                        ? moment(value).format("MM/DD/YYYY")
                        : value,
            },
            {
                headerName: "Merch Division",
                field: "product_h1_name",
            },
            { headerName: "Season Code", field: "season_code" },
            { headerName: "Season Name", field: "season_name" },
            { headerName: "SAP Status", field: "sap_status" },
            {
                headerName: "IP Price Export Status",
                field: "ip_price_export_status",
            },
            {
                headerName: "GFE Pre Pack",
                field: "gfe_pre_pack",
            },
            {
                headerName: "GFE Material Status",
                field: "gfe_material_status",
            },
            {
                headerName: "Merch Org",
                field: "product_h2_name",
            },
            { headerName: "Gender", field: "product_h3_name" },
            { headerName: "Brand", field: "product_h5_name" },
            {
                headerName: "Merch Segment",
                field: "product_h6_name",
            },
            {
                headerName: "Merch Size Group",
                field: "product_h7_name",
            },
            {
                headerName: "Merch Category",
                field: "product_h8_name",
            },
            {
                headerName: "Product Category",
                field: "product_h9_name",
            },
            {
                headerName: "Product Class",
                field: "product_h10_name",
            },
            {
                headerName: "Product Subclass",
                field: "product_h11_name",
            },
            // { headerName: "Product Level 1", field: "" },
            // { headerName: "Product Level 2", field: "" },
            // { headerName: "Product Level 3", field: "" },
            // { headerName: "Product Level 4", field: "" },
            {
                headerName: "Brand Group",
                field: "product_h4_name",
            },
            {
                headerName: "Special Attribute",
                field: "special_attribute",
            },
            {
                headerName: "Board Number",
                field: "board_number",
            },
            {
                headerName: "GFE Pre-Pack",
                field: "gfe_pre_pack",
            },
            { headerName: "Item Number", field: "item_number" },
            {
                headerName: "Legacy Material Number",
                field: "legacy_material_nbr",
            },
            {
                headerName: "Style Number",
                field: "style_number",
            },
            {
                headerName: "Vendor Material Number",
                field: "vendor_material_nbr",
            },
        ]
            .map((item) => {
                const obj = {
                    ...item,
                    cellStyle: ({ rowIndex, data }) => {
                        if (rowIndex == 0)
                            return {
                                background: "#d3d3d3",
                            };
                        if (data.current_selection) {
                            return { background: "#dde0ff" };
                        }
                        return {};
                    },
                };
                if (!("cellRenderer" in item)) {
                    obj.cellRenderer = InnerTableFormatComponent;
                }

                return obj;
            })
            .filter((column) => {
                const { headerName: name, nameForFilter } = column;
                let isFiltered = false;

                if (
                    column?.columnType == innerTableToggleObject?.toggleCellType
                ) {
                    isFiltered = true;
                } else {
                    if (!column?.columnType) isFiltered = true;
                    else return false;
                }

                const foundColumn =
                    appliedTableSettings?.view_data?.columns.find(
                        (item) =>
                            item.name == name || item.name == nameForFilter
                    );

                if (!foundColumn) isFiltered = true;
                return foundColumn ? foundColumn.isSelected : isFiltered;
            });
    }, [appliedTableSettings]);

    const onGridReady = (params) => {
        const childGridBody = gridRef.current.api.gridBodyCtrl.eBodyViewport;
        const parentGridBody =
            parentGridRef.current.api.gridBodyCtrl.eBodyViewport;

        // parentGridBody.addEventListener("scroll", (event) => {
        //     console.log(event);
        //     const scrollLeft = parentGridBody.scrollLeft;

        //     childGridBody.scrollTo(scrollLeft, 0);
        //     // console.log(`Horizontal Scroll Left: ${scrollLeft}`);
        // });
    };

    const gridOptions = {
        columnDefs,
        rowData,
        suppressRowClickSelection: true,
        suppressClickEdit: true,
        suppressMovableColumns: true,
        tooltipInteraction: true,
        tooltipShowDelay: 500,
        popupParent: document.body,
        defaultColDef: {
            minWidth: 200,
            sortable: false,
            filter: true,
            valueFormatter: fallbackFormatter,
        },
        loadingOverlayComponent: Spinner,
        onGridReady,
    };

    useEffect(() => {
        // const gridContainer = document.querySelector(
        //     ".ag-body-horizontal-scroll-viewport"
        // );
        // const detailContainer = document.querySelector(
        //     ".ag-full-width-container"
        // );

        // detailContainer.style.width = "100%";
        // gridContainer.addEventListener("scroll", () => {
        //     const scrollLeft = gridContainer.scrollLeft;
        //     detailContainer.style.transform =
        //         "translateX(-" + scrollLeft + "px)";
        // });

        setTimeout(() => getMaterials(), 100);
    }, []);

    return (
        <div
            style={{ height: "300px", padding: "10px" }}
            className="ag-theme-alpine inner-table-container"
        >
            <AgGridReact ref={gridRef} {...gridOptions} />
        </div>
    );
};

export default InnerTableComponent;
