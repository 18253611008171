import SettingsIcon from "@mui/icons-material/Settings";
import { Button, Grid, Modal, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import Pagination from "rc-pagination";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMarketNames } from "../../../../store/features/market/market";
import filterOverlayStyles from "../../../common/filters/FilterOverlay.module.scss";
import MultiSelect from "../../../common/filters/Select/Select";
import FlatFilter from "../../../common/flatFilter/FlatFilter";
import pageStyles from "../EUPricePlanningFilterAttributesMaterials-New.module.scss";

import CachedIcon from "@mui/icons-material/Cached";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import WarningIcon from "@mui/icons-material/Warning";
import { AgGridReact } from "ag-grid-react";
import { Spinner } from "impact-ui";
import { cloneDeep, isEmpty } from "lodash";
import emptyStateImage from "../../../../assets/images/Empty State.png";
import { toastError } from "../../../../store/features/global/global";
import {
    downloadExcel,
    getPriceFileMaterials,
    validatePastedAttributes,
} from "../../../../store/features/workbench/globalSearch";
import {
    getColumnSettings,
    setCurrentPage,
    updateSimilarPFSMaterials,
    setMaterials as setReduxMaterials,
    getAlertsAndExceptionsAndUpdateState,
} from "../../../../store/features/workbench/workbench";
import { fallbackFormatter } from "../../../../utils/helpers/formatter";
import { onFilterChanged } from "../../../../utils/reactTable/callbacks/callbacks";
import CustomAccordion from "../../../common/customAccordian/CustomAccordion";
import {
    actionRequired,
    attributeColumnDefMap,
    defaultColumnDefs,
    emptyColumn,
    euroFormatter,
    getColPinValue,
    percentageFormatter,
    rightPinCellStyle,
} from "../EUPriceListLevelPrices";
import styles from "../EUPricePlanning.module.scss";
import {
    attributeOptions,
    separatorOptions,
    separatorsMap,
} from "../EUPricePlanningFilterAttributesMaterials-New";
import TableSettings, {
    tableSettingColumnWidthModes,
    tableSettingTabIds,
} from "../TableSettings";
import { getMaterialFetchData } from "../euHelper";
import PriceHistory from "./PriceHistory";
import moment from "moment";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: "0 0.7rem 0 0",
    textAlign: "left",
    boxShadow: "none",
}));

const regionList = ["EMEA", "APAC"].map((item) => ({
    label: item,
    value: item,
}));
let channelListGlobal = ["FULL-PRICE", "OUTLET"].map((item) => ({
    label: item,
    value: item,
}));
export const page = "globalSearch";
const screen = "EU_GLOBAL_SEARCH";
let separatedPastedItems = [];

const CellRendererForInnerTable = ({
    data,
    value,
    setIsLoading,
    materials,
    setMaterials,
    isSimilar,
    parentMaterial,
    selectedMaterialForGlobalSearch,
    setSelectedMaterialForGlobalSearch,
    showPriceHistoryFunc,
}) => {
    const dispatch = useDispatch();

    const {
        filterByAttributes: { materials: storeMaterials },
    } = useSelector((state) => state.workbench);
    const materialsCopy = cloneDeep(storeMaterials);

    const onUpdateClick = () => {
        setIsLoading(true);
        dispatch(
            updateSimilarPFSMaterials({
                source_pfm_id: selectedMaterialForGlobalSearch.id,
                material_pfm_id: data.id,
                search_type: isSimilar ? "similar" : "pfs",
                is_global: true,
            })
        )
            .then((res) => {
                const responseMaterial = res[0];
                const foundIndexForState = materials.findIndex(
                    (item) => item.id == selectedMaterialForGlobalSearch.id
                );
                const parentMaterial = cloneDeep(materials[foundIndexForState]);

                if (isSimilar) {
                    parentMaterial.similar_material_channel =
                        responseMaterial.channel;
                    parentMaterial.similar_material_market_id =
                        responseMaterial.market_id;
                    parentMaterial.similar_material_market_name =
                        responseMaterial.market_name;
                    parentMaterial.similar_material = responseMaterial.material;
                    parentMaterial.similar_material_season =
                        responseMaterial.season;
                    parentMaterial.similar_material_update_type =
                        responseMaterial.update_type;
                    parentMaterial.similar_material_image_url =
                        responseMaterial.image_url;    
                } else {
                    parentMaterial.pfs_was_material_channel =
                        responseMaterial.channel;
                    parentMaterial.pfs_was_material_market_id =
                        responseMaterial.market_id;
                    parentMaterial.pfs_was_market_name =
                        responseMaterial.market_name;
                    parentMaterial.pfs_was_material = responseMaterial.material;
                    parentMaterial.pfs_was_indicative =
                        responseMaterial.pfs_was_indicative;
                    parentMaterial.pfs_was_material_season =
                        responseMaterial.season;
                    parentMaterial.store_price = responseMaterial.store_price;
                    parentMaterial.pfs_was_material_update_type =
                        responseMaterial.update_type;
                    parentMaterial.pfs_was_image_url =
                        responseMaterial.image_url;       
                }

                parentMaterial.msrp_price = responseMaterial.msrp_price;

                const foundIndex = materialsCopy.findIndex(
                    (item) => parentMaterial.id == item.id
                );
                materialsCopy[foundIndex] = parentMaterial;
                materials[foundIndexForState] = parentMaterial;
                dispatch(
                    setReduxMaterials({
                        page: "filterByAttributes",
                        data: [...materialsCopy],
                    })
                );
                setMaterials([...materials]);
                return parentMaterial;
            })
            .then((parentMaterial) => {
                return dispatch(
                    getAlertsAndExceptionsAndUpdateState({
                        screen: "price_grid",
                        material_ids: [
                            parentMaterial.material_number +
                                parentMaterial.season_code,
                        ],
                        market_id: parentMaterial.market_id,
                        region: parentMaterial.region,
                        channel: parentMaterial.channel,
                    })
                );
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setIsLoading(false);
                setSelectedMaterialForGlobalSearch(null);
            });
    };

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
            }}
        >
            {selectedMaterialForGlobalSearch && (
                <Tooltip title="Replace With Current">
                    <div className="pfs-inner-table-replace-button global-search-button">
                        <CachedIcon onClick={onUpdateClick} />
                    </div>
                </Tooltip>
            )}

            <Button
                variant="text"
                onClick={() =>
                    showPriceHistoryFunc({
                        channel: data.channel,
                        material_number: value,
                        market_id: data.market_id,
                        region: data.region,
                        season: data.season_code,
                        pfm_id: data.id,
                        msrp_price: data.msrp_price,
                    })
                }
                style={{ justifyContent: "start" }}
            >
                {value}
            </Button>
        </div>
    );
};

let filterPayload = null;
export default function GlobalSearch({
    selectedMaterialForGlobalSearch,
    setSelectedMaterialForGlobalSearch,
    materials,
    setMaterials,
}) {
    let [selectedMarkets, setSelectedMarkets] = useState([]);
    let [selectedRegions, setSelectedRegions] = useState([]);
    let [selectedChannels, setSelectedChannels] = useState([]);

    const [marketNames, setMarketNames] = useState([]);

    const [filtersExpanded, setFiltersExpanded] = useState(false);
    const [selectedMerchFilters, setSelectedMerchFilters] = useState({});
    const [selectedAttributeFilters, setSelectedAttributeFilters] = useState(
        {}
    );
    const [selectedProductFilters, setSelectedProductFilters] = useState({});
    const [selectedPlanFilters, setSelectedPlanFilters] = useState({});
    const [showModal, setShowModal] = useState(false);

    const [selectedAttributeOptions, setSelectedAttributeOptions] = useState([
        attributeOptions[4],
    ]);
    const [selectedSeparatorOptions, setSelectedSeparatorOptions] =
        useState(separatorOptions);
    const [pasteAreaValue, setPasteAreaValue] = useState("");
    const [validValues, setValidValues] = useState([]);
    const [invalidValues, setInvalidValues] = useState([]);

    const [materialList, setMaterialList] = useState([]);
    const [paginationCount, setPaginationCount] = useState(1000);
    const [paginationOffset, setPaginationOffset] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationCurrentPage, setPaginationCurrentPage] = useState(0);

    const [showTableSettings, setShowTableSettings] = useState(false);
    const [appliedTableSettings, setAppliedTableSettings] = useState(null);

    // PRICE HISTORY
    const [showPriceHistory, setShowPriceHistory] = useState(false);
    const [priceHistorySrc, setPriceHistorySrc] = useState({});

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const gridRef = useRef();

    const {
        priceFileId,
        columnSettings,
        selectedViewIndex,
        userResizedColumns,
    } = useSelector((state) => state.workbench.general);

    const columnDefs = useMemo(() => {
        const attributeColumnDefMapCopy = cloneDeep(attributeColumnDefMap);
        attributeColumnDefMapCopy.superSeason = {
            flag: true,
            columnDef: {
                headerName: "Super Season",
                field: "super_season",
            },
        };
        attributeColumnDefMapCopy.year = {
            flag: true,
            columnDef: {
                headerName: "Year",
                field: "year",
            },
        };

        const selectedTableAttributes = (
            appliedTableSettings?.view_data?.columns || []
        ).filter((col) => col.isSelected && col.id != "storePrice");

        const newColDefs = [];
        const defaultColumnDefsCopy = cloneDeep(defaultColumnDefs).filter(
            (item) => !["ALERTS", "EXCEPTIONS"].includes(item.headerName)
        );

        const materialNumberCol = defaultColumnDefsCopy.find(
            (col) => col.field === "material_number"
        );

        materialNumberCol.width = 175;
        materialNumberCol.cellRenderer = CellRendererForInnerTable;
        materialNumberCol.cellRendererParams = {
            ...materialNumberCol.cellRendererParams,
            page,
            showPriceHistoryFunc: (src) => {
                setPriceHistorySrc(src);
                setShowPriceHistory(true);
            },
        };

        if (selectedMaterialForGlobalSearch) {
            const { parentMaterial, isSimilar } =
                selectedMaterialForGlobalSearch;

            materialNumberCol.cellRendererParams = {
                ...materialNumberCol.cellRendererParams,
                setIsLoading,
                parentMaterial,
                isSimilar,
                materials,
                setMaterials,
                selectedMaterialForGlobalSearch,
                setSelectedMaterialForGlobalSearch,
            };
        }

        newColDefs.push.apply(newColDefs, defaultColumnDefsCopy);

        selectedTableAttributes.forEach(
            ({ id: field, pinned, name: label }) => {
                const columnData = attributeColumnDefMapCopy[field];
                if (columnData) {
                    const { flag, columnDef, multi, skip } = columnData;
                    columnDef.headerName = label;
                    columnDef.field = columnDef.field;

                    if (
                        appliedTableSettings.view_data.columnWidth ===
                            tableSettingColumnWidthModes.CUSTOM &&
                        appliedTableSettings.view_data.widths[field] !==
                            undefined
                    ) {
                        columnDef.width =
                            appliedTableSettings.view_data.widths[field];
                    }

                    if (
                        userResizedColumns[
                            tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS
                        ][field] !== undefined
                    ) {
                        columnDef.width =
                            userResizedColumns[
                                tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS
                            ][field];
                    }

                    columnDef.pinned = getColPinValue(pinned);
                    columnDef.cellStyle =
                        getColPinValue(pinned) === "right"
                            ? {
                                  ...rightPinCellStyle,
                                  ...(columnDef.cellStyle || {}),
                              }
                            : { ...(columnDef.cellStyle || {}) };

                    if (skip) {
                        return;
                    }

                    if (
                        columnDef.field.includes("_date") ||
                        columnDef.field.includes("store_price") ||
                        columnDef.field.includes("_price") ||
                        columnDef.field.includes("discount") ||
                        columnDef.field == "similar_material" ||
                        columnDef.field == "pfs_was_material" ||
                        columnDef.field.includes("is_price_rule_applied") ||
                        columnDef.field == "price_rule_name"
                    )
                        delete columnDef.cellRenderer;

                    if (columnDef.field == "is_price_rule_applied") {
                        columnDef.valueFormatter = ({ value }) =>
                            actionRequired[value]?.text || "-";
                    }

                    if (columnDef.field == "msrp_price") {
                        columnDef.headerName = "MSRP/PFS WAS PRICE";
                    }

                    if (columnDef.field == "wholesale_price") {
                        columnDef.headerName = "WHSL/PFS STORE PRICE";
                        columnDef.valueFormatter = ({ value, data }) => {
                            if (data) {
                                let num =
                                    data.channel.toLowerCase() == "outlet"
                                        ? data.store_price
                                        : value;
                                return euroFormatter({ value: num });
                            }

                            return "-";
                        };
                    }

                    if (
                        [
                            "msrp_effective_date",
                            "first_available_dt",
                            "wholesale_effective_date",
                        ].includes(columnDef.field.toLowerCase())
                    ) {
                        columnDef.valueFormatter = ({ value }) => {
                            const date = moment(value);
                            return date.isValid()
                                ? date.format("MM/DD/YYYY")
                                : "-";
                        };
                    }

                    if (
                        [
                            "msrp_price",
                            "store_price",
                            "pfs_was_indicative",
                        ].includes(columnDef.field.toLowerCase())
                    ) {
                        columnDef.valueFormatter = (props) =>
                            props.value ? euroFormatter(props) : "-";
                    }

                    columnDef.hide = false;

                    if (multi) {
                        const children = [];

                        if (flag) {
                            children.push(columnDef);
                        }

                        for (const pairField of multi.pairKeys) {
                            if (attributeColumnDefMapCopy[pairField]?.flag) {
                                children.push(
                                    attributeColumnDefMapCopy[pairField]
                                        .columnDef
                                );
                            }
                        }

                        if (children.length) {
                            newColDefs.push(emptyColumn);
                            newColDefs.push({ ...multi.columnDef, children });
                        }
                    } else {
                        if (flag) {
                            newColDefs.push(columnDef);
                        }
                    }
                }
            }
        );

        // overriding percentage formatter only for margin columns
        try {
            newColDefs.forEach((column) => {
                if (
                    [
                        "margin_dtc_perc",
                        "margin_wholesale_perc",
                        "discount",
                    ].includes(column.field.toLowerCase()) &&
                    column?.filterParams
                ) {
                    column.filterParams = cloneDeep(column.filterParams);
                    column.filterParams.filters[1].filterParams.valueFormatter =
                        percentageFormatter;
                }
            });
        } catch (e) {
            console.error(e);
        }

        return newColDefs.slice(1).map((item) => {
            const obj = {
                ...item,
                cellStyle: (params) => {
                    const { rowIndex, data } = params;
                    const styleObj = {
                        display: "flex",
                        alignItems: "center",
                    };
                    if (data.isSelectedMaterial)
                        styleObj.background = "#d3d3d3";

                    return styleObj;
                },
            };

            if (item.headerName.toLowerCase() == "market") {
                obj.field = "market_name";
            }

            delete obj.hide;

            return obj;
        });
    }, [page, appliedTableSettings, userResizedColumns, materialList]);

    let channelList = channelListGlobal.filter((item) => {
        if (!selectedMaterialForGlobalSearch) return true;

        if (!selectedMaterialForGlobalSearch?.isSimilar) {
            return item.label === "FULL-PRICE";
        }

        return true;
    });

    useEffect(() => {
        dispatch(getColumnSettings()).then((res) => console.log(res));
        dispatch(setCurrentPage({ page, data: 1 }));

        onTableSettingsApply(
            tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS,
            columnSettings[tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS][
                selectedViewIndex[
                    tableSettingTabIds.GLOBAL_SEARCH_ATTRIBUTE_SETTINGS
                ]
            ]
        );

        // TODO: need to remove this
        // setMaterialList(new Array(1000).fill(dummyData));

        // if (selectedMaterialForGlobalSearch) {
        //     setIsLoading(true);
        // }
        getMarketNames().then((markets) => {
            const fetchedMarketNames =
                markets?.map((market) => ({
                    label: market.market_name,
                    value: market.id,
                })) || [];

            setMarketNames(fetchedMarketNames);
        });
    }, []);

    useEffect(() => {
        if (materialList?.length) onFilterSubmitClick(false);
    }, [paginationOffset]);

    useEffect(() => {
        setTimeout(() => {
            const elementsList = document.querySelectorAll(
                ".rc-pagination-prev, .rc-pagination-next "
            );
            elementsList?.forEach((element) => {
                element.title = "";
            });
        }, 10000);
    }, [materialList]);

    useEffect(() => {
        const separators = [];
        let separatedItems = [
            pasteAreaValue
                .split("")
                .filter((char) => char !== "'")
                .join(""),
        ];

        for (const { value } of selectedSeparatorOptions) {
            separators.push.apply(separators, separatorsMap[value]);
        }

        for (const separator of separators) {
            const temp = [];

            for (const item of separatedItems) {
                temp.push.apply(
                    temp,
                    item
                        .split(separator)
                        .map((str) => str.trim())
                        .filter((str) => str)
                );
            }

            separatedItems = temp;
        }

        separatedPastedItems = separatedItems;
    }, [selectedAttributeOptions, selectedSeparatorOptions, pasteAreaValue]);

    const handleAccordion = () => {
        setFiltersExpanded((prevValue) => !prevValue);
    };

    const onMerchFilterSelect = useCallback((data) => {
        setSelectedMerchFilters(data);
    }, []);

    const onAttributeFilterSelect = useCallback((data) => {
        setSelectedAttributeFilters(data);
    }, []);

    const onProductFilterSelect = useCallback((data) => {
        setSelectedProductFilters(data);
    }, []);

    const onPlanFilterSelect = useCallback((data) => {
        setSelectedPlanFilters(data);
    }, []);

    const onSelectedAttributeChange = (data) => {
        setSelectedAttributeOptions(data.selectedItems);
    };

    const onMarketChange = (selectedItems) => {
        setSelectedMarkets(selectedItems);
        setSelectedRegions([]);
        setSelectedChannels([]);
        setMaterialList([]);
    };

    const onRegionChange = (selectedItems) => {
        setSelectedRegions(selectedItems);
        setSelectedChannels([]);
        setMaterialList([]);
    };

    const onChannelChange = (selectedItems) => {
        setSelectedChannels(selectedItems);
        setMaterialList([]);
    };

    const onSelectedSeparatorChange = (data) => {
        setSelectedSeparatorOptions(data.selectedItems);
    };

    const callValidatePastedAttributes = () => {
        return new Promise((resolve) => {
            const selectedAttribute = selectedAttributeOptions[0].value;
            const filters = {
                [selectedAttribute]: separatedPastedItems,
            };

            if (
                selectedMarkets.length &&
                selectedRegions.length &&
                selectedChannels.length
            ) {
                const data = {
                    market_id: selectedMarkets.map((item) => item.value),
                    region: selectedRegions.map((item) => item.label),
                    channel: selectedChannels.map((item) => item.label),
                    filters,
                };
                setIsLoading(true);

                dispatch(validatePastedAttributes(data))
                    .then((data) => {
                        const { valid_values, invalid_values } = data;

                        setValidValues(valid_values);
                        setInvalidValues(invalid_values);
                        resolve(data);
                    })
                    .finally(() => setIsLoading(false));
            }
        });
    };

    const onPasteAreaValueChange = (e) => {
        setPasteAreaValue(e.target.value);
    };

    const getFilterPayload = async (clickedFromModal) => {
        let submittedFilters = {};

        const selectedAttribute = selectedAttributeOptions[0]?.value;
        if (pasteAreaValue) {
            let valid_values, invalid_values;

            const data = await callValidatePastedAttributes();
            invalid_values = data.invalid_values;
            valid_values = data.valid_values;

            if (invalid_values.length && !clickedFromModal) {
                setShowModal(true);
                return;
            }

            submittedFilters.SELECT_FILTERS_ATTRIBUTE = {
                [selectedAttribute]: valid_values,
            };
        }

        if (!isEmpty(selectedMerchFilters)) {
            submittedFilters.SELECT_FILTERS_MERCH = selectedMerchFilters;
        }

        if (!isEmpty(selectedAttributeFilters)) {
            submittedFilters.SELECT_FILTERS_ATTRIBUTE = {
                ...(submittedFilters?.SELECT_FILTERS_ATTRIBUTE || {}),
                ...selectedAttributeFilters,
            };
        }

        if (!isEmpty(selectedProductFilters)) {
            submittedFilters.SELECT_FILTERS_PRODUCT = selectedProductFilters;
        }

        if (!isEmpty(selectedPlanFilters)) {
            submittedFilters.SELECT_FILTERS_PLANNING = selectedPlanFilters;
        }

        if (isEmpty(submittedFilters)) {
            dispatch(toastError("Please select some filters to continue"));

            return;
        }

        return submittedFilters;
    };

    const onFilterSubmitClick = async (clickedFromModal) => {
        setShowModal(false);

        if (!selectedMarkets?.length) {
            setSelectedMarkets(marketNames);
            selectedMarkets = marketNames;
        }

        if (!selectedRegions?.length) {
            setSelectedRegions(regionList);
            selectedRegions = regionList;
        }

        if (!selectedChannels?.length) {
            setSelectedChannels(channelList);
            selectedChannels = channelList;
        }

        const submittedFilters = await getFilterPayload(clickedFromModal);

        setFiltersExpanded(false);

        getMaterials(submittedFilters);
    };

    const onFilterClearClick = () => {
        onMarketChange([]);
        setSelectedMerchFilters({});
        setSelectedAttributeFilters({});
        setSelectedProductFilters({});
        setSelectedPlanFilters({});
        setPasteAreaValue("");
    };

    const getMaterials = (submittedFilters) => {
        const filters = { selectedMarkets, selectedRegions, selectedChannels };

        if (
            selectedMarkets.length &&
            selectedRegions.length &&
            selectedChannels.length
        ) {
            const data = getMaterialFetchData(
                submittedFilters,
                filters.selectedMarkets,
                filters.selectedRegions,
                filters.selectedChannels,
                priceFileId
            );

            data.market_id = filters.selectedMarkets.map((item) => item.value);
            data.region = filters.selectedRegions.map((item) => item.label);
            data.channel = filters.selectedChannels.map((item) => item.label);

            filterPayload = data;
            const queryParams = {
                offset: paginationOffset,
                limit: paginationCount,
            };

            setIsLoading(true);
            dispatch(getPriceFileMaterials({ page, data, queryParams }))
                .then((res) => {
                    console.log(res);
                    if (!res?.table_data.length) {
                        dispatch(
                            toastError(
                                "No material tagged for the selection you have made"
                            )
                        );
                    }

                    let list = res?.table_data || [];

                    if (selectedMaterialForGlobalSearch) {
                        selectedMaterialForGlobalSearch.isSelectedMaterial = true;
                        list.unshift(selectedMaterialForGlobalSearch);
                    }

                    setTotalRecords(res?.total_count);
                    setMaterialList(list);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const onBackToPasteClick = () => {
        setShowModal(false);
        setValidValues([]);
        setInvalidValues([]);
    };

    const onGridReady = () => {
        gridRef.current.api.addEventListener("filterChanged", () => {
            // updateClearAllFilterStatus();
        });
    };

    const onPaginationChanged = (current, pageSize) => {
        const offset = (current - 1) * pageSize;
        setPaginationCurrentPage(current);
        setPaginationOffset(offset);
    };

    const tableProps = {
        ref: gridRef,
        onGridReady,
        rowData: materialList,
        rowHeight: "55",
        columnDefs,
        // rowSelection: "multiple",
        editType: "fullRow",
        defaultColDef: {
            sortable: true,
            resizable: true,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            valueFormatter: fallbackFormatter,
            cellStyle: {
                display: "flex",
                alignItems: "center",
            },
            filter: "agMultiColumnFilter",
            floatingFilter: true,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
            },
            valueGetter: (params) => params.data[params.colDef.field] ?? "-",
        },
        suppressRowClickSelection: true,
        suppressColumnVirtualisation: true,
        suppressClickEdit: true,
        suppressMovableColumns: true,
        onFilterChanged,
        // onModelUpdated: onTableDataChange,
        enableRangeSelection: true,
        excelStyles: [
            {
                id: "bigNumber",
                numberFormat: {
                    format: "0",
                },
            },
        ],
        // onColumnResized: onColumnResize, // TODO: add this as well
        tooltipInteraction: true,
        tooltipShowDelay: 500,
        popupParent: document.body,
        // pagination: true,
        // paginationPageSize: paginationCount,
        // onPaginationChanged,
        // paginationPageSizeSelector: [200, 500, 1000],
    };

    const onTableSettingsApply = (tabId, view) => {
        setAppliedTableSettings(view);
        setShowTableSettings(false);

        // dispatch(
        //     setUserResizedColumns({
        //         tabId,
        //         newData: {},
        //     })
        // );
    };

    const onTableSettingsClose = () => {
        setShowTableSettings(false);
    };

    const downloadHandler = () => {
        const data = filterPayload;
        data.attribute_settings = {};

        (columnDefs || []).forEach((columnDef) => {
            data.attribute_settings[columnDef.field] = columnDef.headerName;
        });

        dispatch(downloadExcel({ data }));
    };

    let priceHistoryStyles = {};

    if (selectedMaterialForGlobalSearch) {
        priceHistoryStyles = {
            top: "0px",
            height: "100%",
        };
    }

    const getInvalidaMaterialsText = () => {
        if (validValues.length) {
            return (
                <>
                    The uploaded list contains{" "}
                    <span className="font-bold">
                        {invalidValues.length} item(s)
                    </span>{" "}
                    that&nbsp;
                </>
            );
        }

        return "All the items pasted";
    };

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <Spinner />
                </div>
            ) : (
                <Grid
                    container
                    spacing={2}
                    columns={{ xs: 2, sm: 12, md: 12, lg: 12 }}
                    style={{ margin: "15px 0 0 15px" }}
                >
                    <div
                        className="center-space-between"
                        style={{
                            width: "calc(100% - 69px)",
                            marginLeft: "17px",
                        }}
                    >
                        <div className="page-title">Global Search</div>
                        {selectedMaterialForGlobalSearch && (
                            <div
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={() =>
                                    setSelectedMaterialForGlobalSearch(null)
                                }
                            >
                                <CloseIcon />
                            </div>
                        )}
                    </div>
                    <Grid
                        columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                        style={{ width: "100%" }}
                    >
                        <div
                            style={{
                                width: "calc(100% - 70px)",
                                border: "1px solid #C8CED7",
                                borderRadius: "3px",
                                margin: 16,
                            }}
                        >
                            <CustomAccordion
                                label="Select Filters"
                                expanded={filtersExpanded}
                                handleAccordion={handleAccordion}
                                childProps={{
                                    className: "expanded-accordion",
                                    style: { padding: "0px 0px 10px" },
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    columns={{
                                        xs: 2,
                                        sm: 12,
                                        md: 12,
                                        lg: 12,
                                    }}
                                    style={{ margin: "0 0 32px 0" }}
                                >
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item
                                            className="filterGroup"
                                            // style={{ padding: "0 20px 0 0" }}
                                        >
                                            <label className="select-label">
                                                Select Market
                                            </label>
                                            <MultiSelect
                                                initialData={marketNames}
                                                selectedOptions={
                                                    selectedMarkets
                                                }
                                                updateSelected={(data) =>
                                                    onMarketChange(
                                                        data.selectedItems
                                                    )
                                                }
                                                is_multiple_selection
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item className="filterGroup">
                                            <label className="select-label">
                                                Select Region
                                            </label>
                                            <MultiSelect
                                                initialData={regionList}
                                                selectedOptions={
                                                    selectedRegions
                                                }
                                                updateSelected={(data) =>
                                                    onRegionChange(
                                                        data.selectedItems
                                                    )
                                                }
                                                is_multiple_selection
                                                hideSearch
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={3} lg={2}>
                                        <Item className="filterGroup">
                                            <label className="select-label">
                                                Select Channel
                                            </label>
                                            <MultiSelect
                                                initialData={channelList}
                                                selectedOptions={
                                                    selectedChannels
                                                }
                                                updateSelected={(data) =>
                                                    onChannelChange(
                                                        data.selectedItems
                                                    )
                                                }
                                                is_multiple_selection
                                                hideSearch
                                                updateSelectedOnEachSelection
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                                <div
                                    style={{
                                        border: "1px solid #C8CED7",
                                        borderRadius: "3px",
                                    }}
                                >
                                    <CustomAccordion
                                        label="Merch Division"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_MERCH_DIVISION"
                                            onFilterSelect={onMerchFilterSelect}
                                            selectedFilters={
                                                selectedMerchFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Attributes"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_ATTRIBUTES"
                                            onFilterSelect={
                                                onAttributeFilterSelect
                                            }
                                            selectedFilters={
                                                selectedAttributeFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Merch Hierarchy"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_MERCH"
                                            onFilterSelect={onMerchFilterSelect}
                                            selectedFilters={
                                                selectedMerchFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Product Hierarchy"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_PRODUCT"
                                            onFilterSelect={
                                                onProductFilterSelect
                                            }
                                            selectedFilters={
                                                selectedProductFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Planning Hierarchy"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_PLANNING"
                                            onFilterSelect={onPlanFilterSelect}
                                            selectedFilters={
                                                selectedPlanFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Brand Group"
                                        defaultExpanded={false}
                                    >
                                        <FlatFilter
                                            screenName="SELECT_FILTERS_BRAND_GROUP"
                                            onFilterSelect={onMerchFilterSelect}
                                            selectedFilters={
                                                selectedMerchFilters
                                            }
                                        />
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <CustomAccordion
                                        label="Select and Paste Attributes"
                                        defaultExpanded={false}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "8px",
                                                alignItems: "flex-start",
                                                textAlign: "left",
                                            }}
                                        >
                                            <div style={{ width: "305px" }}>
                                                <label
                                                    className={`${pageStyles["sub-title"]} select-label mb-4`}
                                                >
                                                    Select attribute{" "}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <MultiSelect
                                                    className={`${pageStyles["dropdown"]}`}
                                                    initialData={
                                                        attributeOptions
                                                    }
                                                    selectedOptions={
                                                        selectedAttributeOptions
                                                    }
                                                    updateSelected={
                                                        onSelectedAttributeChange
                                                    }
                                                    hideClearSelection
                                                    updateSelectedOnEachSelection
                                                />
                                            </div>

                                            <div style={{ width: "305px" }}>
                                                <label
                                                    className={`${pageStyles["sub-title"]} select-label`}
                                                >
                                                    Select separators{" "}
                                                    <span
                                                        style={{
                                                            color: "red",
                                                        }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <MultiSelect
                                                    className={`${pageStyles["dropdown"]}`}
                                                    initialData={
                                                        separatorOptions
                                                    }
                                                    selectedOptions={
                                                        selectedSeparatorOptions
                                                    }
                                                    updateSelected={
                                                        onSelectedSeparatorChange
                                                    }
                                                    updateSelectedOnEachSelection
                                                    is_multiple_selection
                                                />
                                            </div>
                                        </div>
                                        <div className="textarea-container">
                                            <label
                                                className={`${pageStyles["sub-title"]} select-label`}
                                            >
                                                Paste values
                                            </label>
                                            <textarea
                                                className={`${pageStyles["paste-area"]}`}
                                                placeholder="Kindly paste the values here and click on 'Submit' to see results"
                                                value={pasteAreaValue}
                                                onChange={
                                                    onPasteAreaValueChange
                                                }
                                            ></textarea>
                                        </div>
                                    </CustomAccordion>
                                    <div
                                        className={`${filterOverlayStyles.divider}`}
                                    ></div>
                                    <div
                                        className={`${filterOverlayStyles["action-container"]}`}
                                    >
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                onFilterSubmitClick(false)
                                            }
                                        >
                                            Submit
                                        </Button>
                                        <Button
                                            className={`${filterOverlayStyles["tertiary-button"]}`}
                                            onClick={onFilterClearClick}
                                        >
                                            Clear Filter
                                        </Button>
                                    </div>
                                </div>
                            </CustomAccordion>
                        </div>
                    </Grid>
                    {materialList?.length > 0 && (
                        <Grid
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
                            style={{ width: "calc(100% - 40px)" }}
                        >
                            {!selectedMaterialForGlobalSearch && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        margin: "0 14px",
                                        gap: "10px",
                                    }}
                                >
                                    <Tooltip title="Table settings">
                                        <Button
                                            variant="contained"
                                            onClick={() =>
                                                setShowTableSettings(true)
                                            }
                                        >
                                            <SettingsIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Download Materials">
                                        <Button
                                            variant="contained"
                                            onClick={downloadHandler}
                                        >
                                            <DownloadIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                            <div
                                style={{
                                    height: "calc(100vh - 340px)",
                                    margin: "15px",
                                }}
                                className="ag-theme-alpine"
                            >
                                <AgGridReact {...tableProps} />
                            </div>
                            <Pagination
                                className="pagination-container"
                                showTotal={(total, range) =>
                                    `${range[0]} - ${range[1]} of ${total}`
                                }
                                // showQuickJumper={false}
                                // showPrevNextJumpers={false}
                                current={paginationCurrentPage}
                                pageSize={paginationCount}
                                total={totalRecords}
                                // align="end"
                                onChange={onPaginationChanged}
                                prevIcon={() => "<"}
                                nextIcon={() => ">"}
                                jumpPrevIcon={() => "<<"}
                                jumpNextIcon={() => ">>"}
                            />
                        </Grid>
                    )}
                    {!filtersExpanded && !materialList?.length && (
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="global-seatch-empty-state-container">
                                <img
                                    src={emptyStateImage}
                                    alt="Empty Data On Load"
                                />
                                <div>
                                    <h3>
                                        Please select the above fields, to
                                        proceed and select filters
                                    </h3>
                                    <p>
                                        Use filters and copy feature to add
                                        combine data
                                    </p>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setFiltersExpanded(true);
                                    }}
                                >
                                    Select&nbsp;Filters
                                </Button>
                            </div>
                        </div>
                    )}
                    <Modal
                        open={showModal}
                        aria-labelledby="copy-paste-confirmation-modal"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <div className="copy-paste-confirmation-modal flex-column">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="modal-title">
                                    Paste Attributes Confimation Prompt
                                </div>
                                <div
                                    className="cross-icon"
                                    onClick={() => setShowModal(false)}
                                >
                                    &#10005;
                                </div>
                            </div>
                            <div>
                                <div className="warning_alert">
                                    <p>
                                        <WarningIcon />
                                        {getInvalidaMaterialsText()}
                                        don't belong in the selected
                                        region-market-channel combination
                                    </p>
                                </div>
                                <div className="alert_attributes_container">
                                    {invalidValues.map((item) => (
                                        <span>{item}</span>
                                    ))}
                                </div>
                            </div>
                            <div
                                className={`${styles["modal-buttons"]} button-group-flex`}
                            >
                                <Button
                                    variant="text"
                                    onClick={onBackToPasteClick}
                                >
                                    Back
                                </Button>
                                {validValues?.length > 0 && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            onFilterSubmitClick(true);
                                        }}
                                    >
                                        Proceed With Valid Material
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Modal>
                    {showPriceHistory ? (
                        <PriceHistory
                            src={priceHistorySrc}
                            onClose={() => setShowPriceHistory(false)}
                            isFromPopUp={!!selectedMaterialForGlobalSearch}
                        />
                    ) : null}
                    {showTableSettings && (
                        <>
                            <div
                                className="drawer-inner-full-height sm"
                                style={priceHistoryStyles}
                            >
                                <TableSettings
                                    globalSearchSettings
                                    onApply={onTableSettingsApply}
                                    onClose={onTableSettingsClose}
                                    selectedChannels={selectedChannels}
                                />
                            </div>
                            <div className="drawer-inner-full-height-cover" />
                        </>
                    )}
                </Grid>
            )}
        </>
    );
}
